import { env } from '../env';

function getAppData(appName) {
  return ((env().fedops || {}).apps || {})[appName];
}

export function initializeFedops() {
  env().fedops = env().fedops || {};
  env().fedops.apps = env().fedops.apps || {};
}

export function initializeApp(appName) {
  env().fedops.apps[appName] = env().fedops.apps[appName] || {};
}

export function initializeGlobal(appName) {
  initializeFedops();
  initializeApp(appName);
}

export function getLoadStartTime(appName) {
  return (getAppData(appName) || {}).startLoadTime || 0;
}

export function setLoadStartTime(appName, startLoadTime) {
  return ((getAppData(appName) || {}).startLoadTime = startLoadTime);
}

export function setSessionId(sessionId) {
  return ((env().fedops || {}).sessionId = sessionId);
}
