export default {
  src: 72,
  endpoint: 'http-client-poc',
  httpRequest: {
    eventId: 1100,
  },
  httpResponse: {
    eventId: 1101,
  },
  httpResponseTime: {
    eventId: 1102,
  },
};
