const envFallback = {};

export const env = () => {
  if (typeof window !== 'undefined') {
    return window;
  } else if (typeof globalThis !== 'undefined') {
    /* global globalThis */
    return globalThis;
  } else if (typeof global !== 'undefined') {
    return global;
  } else if (typeof WorkerGlobalScope !== 'undefined') {
    /* global WorkerGlobalScope */
    return WorkerGlobalScope;
  } else {
    return envFallback;
  }
};

export function hasSendBeacon() {
  return typeof navigator !== 'undefined' && navigator && navigator.sendBeacon;
}

export function sendBeacon(url) {
  return navigator.sendBeacon(url);
}
